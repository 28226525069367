import * as React from 'react';
import YouTube from 'react-youtube';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import Quote from '../../components/quote';
import { StaticImage } from 'gatsby-plugin-image';

const HSEM = ({ location }) => {
  const pageTitle =
    'How To Start Earning Money As An Independent Web Developer 💪💻💰';
  const fxWood = <StaticImage src="./images/fxwood.jpeg" alt="FX Wood photo" />;
  const adrianePurdy = (
    <StaticImage src="./images/adrianepurdy.jpeg" alt="Adriane Purdy photo" />
  );
  const stuartBentley = (
    <StaticImage src="./images/stuartpbentley.jpg" alt="Stuart Bentley photo" />
  );
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="Learn how to start your web development career without waiting, hoping, or begging for someone to hire you. Build your career on your own terms."
        pathname={location.pathname}
      />
      <Article>
        <h2>
          Jumping through hoops in interview after interview, only to hear you
          don't have enough experience <em>sucks</em>.
        </h2>
        <StaticImage
          src="./images/man-frustrated-at-laptop-opt.jpg"
          alt="Overhead shot of a man with his head on a desk in front of his laptop, hands in his hair, apparently frustrated"
          className="f-left"
        />
        <p>
          <b>I hated it too.</b> That’s why I found an alternative. I built this
          video course to teach <em>you</em> how you can have the web
          development career you want without all the 💩 you <em>don’t</em>{' '}
          want.
        </p>
        <h1 className="fancy" style={{ marginBottom: 0 }}>
          The How to Start Earning Money as an Independent Web
          Dev&shy;elop&shy;er Video Course
          <br />
          <small>(or 🚀💰🧑‍💻📺🎓 if you prefer emoji)</small>
        </h1>
        <p>
          In this video course, you’ll learn to skip the interviews and start
          your web development career on your terms.
        </p>
        <h2>
          This method is based on my own experience going from bootcamp to
          career.
        </h2>
        <p>
          When I graduated from coding bootcamp a few years ago, I was sure that
          my skills would be valuable to an employer and I’d get hired right
          away. Unfortunately, none of the places I applied to shared my
          confidence. I submitted quite a few applications before realizing{' '}
          <strong>
            no one was going to hire me because I had no experience
          </strong>
          .
        </p>
        <p>
          My bank account was looking grim, so I had to find another way. I
          picked up my first freelance gig and realized I could build a career
          on this. Gigs were hard to come by though. I wasn’t sure where to look
          besides Upwork and reddit, but those gigs were mostly of low quality.
        </p>
        <p>
          Around the same time, I started hanging out at an organization that
          supported startups in my hometown at that time called the Knoxville
          Entrepreneur Center. I showed up pretty much every time the doors were
          open, and the people there got to know me and what I do.
        </p>
        <p>
          Through these new relationships, I started getting work. The gigs paid
          well, and I realized I enjoyed working with small tech startups. I
          learned more about the problems startups faced which made me much more
          credible when talking to them. When people in this community needed a
          web developer, they automatically thought of me.{' '}
          <strong>I had stumbled into my niche.</strong>
        </p>
        <h2>
          The things most people try to get their first jobs just don’t work. 😖
        </h2>
        <p>You might…</p>
        <ul>
          <li>
            <b>
              lean on your bootcamp’s or school’s career placement department.
            </b>{' '}
            Good luck since they already have your money.
          </li>
          <li>
            <b>
              keep shotgunning resumes to every company that’s hiring junior
              developers.
            </b>{' '}
            If you have enough money in the bank and enough fortitude to handle
            rejection after rejection, you’ll probably make it… eventually.
          </li>
          <li>
            <b>
              try shopping for freelance gigs on platforms like Upwork and
              Fivver.
            </b>{' '}
            The “race to the bottom” pricing on these platforms will make your
            new career unsustainable.
          </li>
        </ul>
        <p>
          At some point while you’re trying these strategies that don’t work,
          someone will come along and convince you to work for them for free,
          promising to give you exposure and to act as a reference the next time
          you apply. Most of the time, these benefits never come or don’t get
          you any closer to the job you want.
        </p>
        <h2>
          The solution is to take control of your web development career and
          stop waiting for someone else to take a chance on you. 🥺
        </h2>
        <p>
          Once you have figured out your niche,{' '}
          <b>you can pave your own path into web development</b> as a
          freelancer. You’re no longer dependent on everyone else to take a
          chance on you. You can start getting the experience you would need for
          a full-time gig while also having tons of freedom and getting paid for
          it.
        </p>
        <p>
          That takes care of the problem of getting your first job, but becoming
          a self-sufficient freelancer solves problems you didn’t even know you
          had.
        </p>
        <p>
          When one of your clients ultimately comes around asking you to work
          for them full time (and they will if you do good work), you are in a
          position of power in the negotiation. You don’t have to take the first
          offer for fear nothing else will come your way. You can ask for
          exactly what you want. If they don’t want to give it to you, you can
          just keep running your freelance business instead.
        </p>
        <h2>Don't let the boss make bad decisions that impact you. 🤬</h2>
        <p>
          <em>Be</em> the boss, so you can make the decisions that build the
          career you want.
        </p>
        <ul>
          <li>
            Did you end up with a bad client?{' '}
            <b>Fire them and find new ones.</b> Try doing that with your prickly
            new engineering lead at Amazon. The great part is that you can
            define “bad” however <em>you</em> want. Maybe it’s clients who don’t
            respond quickly when you have questions, clients who operate in
            industries you find problematic, or clients who expect fast
            turnaround. Maybe it’s all of the above.
          </li>
          <li>
            Don’t want to be on-call or work weekends? OK, cool. Either charge
            enough for those services that you’re happy to offer them or{' '}
            <b>simply don’t offer them</b>. Since you’re going to have to deal
            with the consequences of those decisions, you should get to make the
            call.
          </li>
        </ul>
        <p>
          As a freelancer,{' '}
          <b>
            you have the power to control who you work with and the way you work
          </b>
          . As a freelancer with a niche, you have the number one tool to find
          all the clients you need to make your business prosper.
        </p>
        <h2>Let’s talk about the course. 📺</h2>
        <p>
          By the end of this course, you’ll have your freelance web development
          niche along with a list of places you can go to talk to businesses in
          your niche. You’ll know what to say once you get to those places, and
          you’ll know how to start building trust so you can convert prospects
          into clients. You’ll have a process that will allow you to build a
          career with these new skills.
        </p>
        <p>
          From there, you can stick to freelancing, or you can follow the
          opportunity into one of the full time offers you get… or wherever else
          it leads. You’ll have control over how you grow your career.
        </p>
        <h2>Why You Should Care</h2>
        <p>
          Selecting the right niche starts with a strategy that gets you past
          common roadblocks.
        </p>
        <ul>
          <li>
            <b>Start building trust.</b> You’ll have an endless supply of great
            clients so that you never have to take on bad ones just to make ends
            meet.
          </li>
          <li>
            <b>Find the best niche for you so that you can get paid.</b> Stop
            looking for the one and only perfect niche so that you can start
            getting paid <em>now</em>!
          </li>
          <li>
            <b>Know which niches to avoid</b> so that you’re always getting paid
            what you’re worth.
          </li>
          <li>
            <b>Build a web development career</b> that pays the salary you set,
            lets you work for the clients you choose, and ensures you won’t ever
            have to worry about being laid off.
          </li>
        </ul>
        <h2>Here’s what you’ll do in the course:</h2>
        <ol>
          <li>Build a list of potential niches</li>
          <li>Select your niche</li>
          <li>Find where the good clients hang out (even during COVID-19!)</li>
          <li>
            Learn how to find an irresistible offer for your ideal clients and
            market it to them
          </li>
          <li>Build trust with prospects</li>
          <li>Grow a list of now or future prospects</li>
        </ol>
        <h2>What’s Included</h2>
        <ul>
          <li>
            21 videos divided into 6 sections (corresponding to the outline
            above) to help you understand the benefits and select a web
            development niche so that you can reach profitability quickly{' '}
            <i>without</i> having to watch hours of videos. Videos include
            English captions.
          </li>
          <li>
            <strong>Basic + Pricing, Deluxe-</strong> A freelance contract
            template with annotated example contract. With this contract, you’ll
            be ready to start every project professionally and with a clear
            expectation of the responsibilities of both you and your client.
          </li>
          <li>
            <strong>Basic + Pricing, Deluxe-</strong> A bonus copy of my book
            Freelance Web Development Pricing so you’ll have the paths to both
            find your perfect clients and set your perfect price.
          </li>
          <li>
            <strong>Deluxe-</strong>&nbsp;4 30-minute coaching calls with me to
            discuss your progress, get critical feedback, and tee up your next
            steps. Together we’re going to push through the roadblocks unique to
            you so that you never get stuck not being sure how something applies
            to your specific situation. I’ll keep you accountable so you can
            start seeing a return on your investment.
          </li>
        </ul>
        <h2>Check out this sample video</h2>
        <YouTube videoId="W3HGrsTxxOM"></YouTube>
        <p>Like what you see?</p>
        <a
          className="button -large -cta"
          href="https://raddevon.gumroad.com/l/qnwls"
          style={{ marginBottom: '4em' }}>
          Get Your Copy!
        </a>
        <h2>You'll probably like it, just like these fine people!</h2>
        <Quote
          photo={fxWood}
          color="var(--color-primary-tint1)"
          attribution="FX Wood">
          Devon can talk this talk because he has walked this walk.
        </Quote>
        <Quote photo={adrianePurdy} attribution="Adriane Purdy" flipped={true}>
          Rad Devon is super RAD! Relatable, Awesome, Devon! What could have
          been a boring workshop was actually very interesting, informative, and
          colorful.
        </Quote>
        <p>
          If you don’t, though, never fear! You can get{' '}
          <strong>a full refund</strong> within 14 days of your purchase.
        </p>
        <h2>FAQs</h2>
        <dl>
          <dt>
            What if I’ve never done web development professionally? Isn’t
            freelancing only for super experts?
          </dt>
          <dd>
            <p>
              No way! In a world where even junior job descriptions demand 3-5
              years of experience, freelancing is the best way for new
              developers to start their careers. This course is specifically
              designed to help new developers go pro by finding a freelance
              niche.
            </p>
          </dd>
          <dt>
            Can this course help me if I’m just starting to learn web
            development?
          </dt>
          <dd>
            <p>
              If you can build and deploy a project on your own, yes. If you’re
              not that far along yet, this course probably won’t help you at
              this point. Don’t wait until you feel ready (because you never
              will), but make sure you at least know enough to ship something,
              whether that’s a static site, a simple web app, or a WordPress
              blog.
            </p>
          </dd>
          <dt>
            Why is this course so short? The courses on Udemy have hundreds of
            hours of video!
          </dt>
          <dd>
            <p>
              This course is short because I took the time to make it as short
              as I could. That’s right. I spent my time making sure this course
              has only what you need rather than trying to inflate the number of
              hours of video in the course. I do this because your time is
              valuable, and you’re not here to watch videos. You’re here to go
              pro as a web developer. This course will get you there without
              wasting your time.
            </p>
          </dd>
          <dt>Can't I just learn all this on my own without the course?</dt>
          <dd>
            <p>You can, but you’ll have a few additional challenges:</p>
            <ul>
              <li>
                Sorting through the ridiculous number of resources on the
                internet to find what’s legit and what isn’t
              </li>
              <li>
                Finding the sources that present the information in a way that
                makes sense to you
              </li>
              <li>
                Going through a few iterations of trial and error before you
                land on a system that works for you
              </li>
            </ul>
            <p>
              It can be done… it’s just harder. Making it harder increases the
              risk you’ll quit before you can make it work. Also, with the
              deluxe edition, you’ll have the additional benefit of
              accountability by way of the coaching calls.
            </p>
          </dd>
          <dt>How are the videos delivered?</dt>
          <dd>
            <p>
              You can download copies of the videos to keep, or you can stream
              them and watch them in your browser.
            </p>
          </dd>
          <dt>Are the videos captioned?</dt>
          <dd>
            <p>
              Yes, full English captions are provided for all videos in the
              course.
            </p>
          </dd>
          <dt>What if I don’t like the course?</dt>
          <dd>
            <p>You can request a full refund within 14 days.</p>
          </dd>
          <dt>Why is there a time limit to buy the course?</dt>
          <dd>
            <p>
              I offer coaching with the deluxe version of the course. Since
              there’s only one of me and my time is limited, I’ve added this
              time limit for the launch cohort so I can give everyone a great
              experience (and hopefully still retain what’s left of my sanity).
            </p>
          </dd>
        </dl>
        <Quote photo={stuartBentley} attribution="Stuart Bentley">
          Pretty sensible advice - honest about what has a good record of
          working, and what would be a dead-end.
        </Quote>
        <h2>What’s Included</h2>
        <ul>
          <li>
            21 videos divided into 6 sections (corresponding to the outline
            above) to help you understand the benefits and select a web
            development niche so that you can reach profitability
            quickly&nbsp;&nbsp;having to watch hours of videos. Videos include
            English captions.
          </li>
          <li>
            <strong>Basic + Pricing, Deluxe-</strong> A freelance contract
            template with annotated example contract. With this contract, you’ll
            be ready to start every project professionally and with a clear
            expectation of the responsibilities of both you and your client.
          </li>
          <li>
            <strong>Basic + Pricing, Deluxe-</strong> A bonus copy of my book
            Freelance Web Development Pricing so you’ll have the paths to both
            find your perfect clients and set your perfect price.
          </li>
          <li>
            <strong>Deluxe-</strong>&nbsp;4 30-minute coaching calls with me to
            discuss your progress, get critical feedback, and tee up your next
            steps. Together we’re going to push through the roadblocks unique to
            you so that you never get stuck not being sure how something applies
            to your specific situation. I’ll keep you accountable so you can
            start seeing a return on your investment.
          </li>
        </ul>
        <a
          className="button -large -cta"
          href="https://raddevon.gumroad.com/l/qnwls">
          Start Building Your New Career!
        </a>
      </Article>
    </Layout>
  );
};
export default HSEM;
